<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="200"
    height="200"
    viewBox="0 0 200 200"
    xml:space="preserve"
  >
    <g
      transform="matrix(2.48 0 0 2.48 5 10)"
      id="89f50520-1824-4f7a-9a68-2c8c1aca623e"
    >
      <polygon
        :fill="outercol"
        style="
          transform: scale(0.75);
          stroke: #111827;
          stroke-width: 2;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill-rule: nonzero;
          opacity: 1;
        "
        vector-effect="non-scaling-stroke"
        points="50 3,100 28,100 75,50 100,3 75,3 25 "
      />
    </g>
    <g
      transform="matrix(1.7 0 0 1.7 100 105)"
      id="3c92d7f0-51ca-421a-bdbd-b76301b78e78"
    >
      <rect
        :fill="insidecol"
        style="
          transform: scale(0.75);
          stroke: #111827;
          stroke-width: 2;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill-rule: nonzero;
          opacity: 1;
        "
        vector-effect="non-scaling-stroke"
        x="-33.0835"
        y="-33.0835"
        rx="0"
        ry="0"
        width="66.167"
        height="66.167"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    outercol: {
      type: String,
      default: "",
    },
    insidecol: {
      type: String,
      default: "",
    },
  },
};
</script>