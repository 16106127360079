<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="200"
    height="200"
    viewBox="0 0 200 200"
    xml:space="preserve"
  >
    <g
      transform="matrix(2.43 0 0 2.43 100 100)"
      id="bef8a804-1555-4b54-907e-10b0f1e866f3"
    >
      <circle
        :fill="outercol"
        style="
          stroke: #111827;
          stroke-width: 2;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill-rule: nonzero;
          opacity: 1;
        "
        vector-effect="non-scaling-stroke"
        cx="0"
        cy="0"
        r="35"
      />
    </g>
    <g
      transform="matrix(1.42 0 0 1.42 50 50)"
      id="e53a7057-6cdb-42b0-9c91-c88e9ec66248"
    >
      <polygon
        :fill="insidecol"
        style="
          transform: scale(0.7);
          stroke: #111827;
          stroke-width: 2;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill-rule: nonzero;
          opacity: 1;
        "
        vector-effect="non-scaling-stroke"
        points="50 3,100 28,100 75,50 100,3 75,3 25 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    outercol: {
      type: String,
      default: "",
    },
    insidecol: {
      type: String,
      default: "",
    },
  },
};
</script>