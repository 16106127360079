<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="200"
    height="200"
    viewBox="0 0 200 200"
    xml:space="preserve"
  >
    <g
      transform="matrix(2.58 0 0 2.58 101.01 99.77)"
      id="d40fd5dc-bdbc-439b-ba88-2a88ebb569f7"
    >
      <polygon
        :fill="outercol"
        style="
          stroke: #111827;
          stroke-width: 2;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill-rule: nonzero;
          opacity: 1;
        "
        vector-effect="non-scaling-stroke"
        points="-37.43,32.41 0,-32.41 37.43,32.41 "
      />
    </g>
    <g
      transform="matrix(1.48 0 0 1.48 49 78)"
      id="b2e72858-895b-4fe7-84a7-0189786e8aa2"
    >
      <polygon
        :fill="insidecol"
        style="
          transform: scale(0.7);
          stroke: #111827;
          stroke-width: 2;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-dashoffset: 0;
          stroke-linejoin: miter;
          stroke-miterlimit: 4;
          fill-rule: nonzero;
          opacity: 1;
        "
        vector-effect="non-scaling-stroke"
        points="50 3,100 28,100 75,50 100,3 75,3 25 "
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    outercol: {
      type: String,
      default: "",
    },
    insidecol: {
      type: String,
      default: "",
    },
  },
};
</script>