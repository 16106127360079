<template>
  <div v-if="option" class="text-center">
    <TriangleTriangle
      v-if="option.outershape === 3 && option.innershape === 3"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <TriangleCricle
      v-else-if="option.outershape === 3 && option.innershape === 360"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <TriangleSquare
      v-else-if="option.outershape === 3 && option.innershape === 4"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <TriangleHexagon
      v-else-if="option.outershape === 3 && option.innershape === 6"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />

    <SquareSquare
      v-else-if="option.outershape === 4 && option.innershape === 4"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <SquareCricle
      v-else-if="option.outershape === 4 && option.innershape === 360"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <SquareTriangle
      v-else-if="option.outershape === 4 && option.innershape === 3"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <SquareHexagon
      v-else-if="option.outershape === 4 && option.innershape === 6"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />

    <CricleCricle
      v-else-if="option.outershape === 360 && option.innershape === 360"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <CricleSquare
      v-else-if="option.outershape === 360 && option.innershape === 4"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <CricleTriangle
      v-else-if="option.outershape === 360 && option.innershape === 3"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <CricleHexagon
      v-else-if="option.outershape === 360 && option.innershape === 6"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />

    <HexagonHexagon
      v-else-if="option.outershape === 6 && option.innershape === 6"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <HexagonCricle
      v-else-if="option.outershape === 6 && option.innershape === 360"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <HexagonSquare
      v-else-if="option.outershape === 6 && option.innershape === 4"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />
    <HexagonTriangle
      v-else-if="option.outershape === 6 && option.innershape === 3"
      outercol="#ffffff80"
      insidecol="#ffffff80"
    />

    <div v-else>Not match</div>
  </div>
</template>

<script>
import TriangleTriangle from "./TriangleTriangle";
import TriangleCricle from "./TriangleCricle";
import TriangleSquare from "./TriangleSquare";
import TriangleHexagon from "./TriangleHexagon";

import SquareSquare from "./SquareSquare";
import SquareCricle from "./SquareCricle";
import SquareTriangle from "./SquareTriangle";
import SquareHexagon from "./SquareHexagon";

import CricleSquare from "./CricleSquare";
import CricleCricle from "./CricleCricle";
import CricleTriangle from "./CricleTriangle";
import CricleHexagon from "./CricleHexagon";

import HexagonHexagon from "./HexagonHexagon";
import HexagonCricle from "./HexagonCricle";
import HexagonSquare from "./HexagonSquare";
import HexagonTriangle from "./HexagonTriangle";

export default {
  components: {
    TriangleTriangle,
    TriangleCricle,
    TriangleSquare,
    TriangleHexagon,
    SquareSquare,
    SquareCricle,
    SquareTriangle,
    SquareHexagon,
    CricleSquare,
    CricleCricle,
    CricleTriangle,
    CricleHexagon,
    HexagonHexagon,
    HexagonCricle,
    HexagonSquare,
    HexagonTriangle,
  },
  props: {
    option: {
      type: Object,
      default: null,
    },
  },
};
</script>